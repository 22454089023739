<script setup>

defineProps({
    show: Boolean
})

const setMaxHeight = (el) => el.style.maxHeight = el.children[0].offsetHeight + 'px'

const setDefaultHeight = (el) => el.style.maxHeight = ''

const setZeroHeight = (el) => el.style.maxHeight = '0'

</script>

<template>
    <transition
        name="toggle"
        @before-enter="setZeroHeight"
        @enter="setMaxHeight"
        @after-enter="setDefaultHeight"

        @before-leave="setMaxHeight"
        @leave="setZeroHeight"
        @after-leave="setDefaultHeight"
    >
        <div
            v-show="show"
            class="toggle-element"
        >
            <slot />
        </div>
    </transition>
</template>

<style scoped>
.toggle-enter-active,
.toggle-leave-active {
    transition: max-height .3s linear;
    overflow: hidden;
}
</style>